:root {
  --mc-dark   : rgb(70, 195, 190);
  --mc-dg   : rgb(50, 130, 160);
  --dark: rgb(5, 5, 10);
  --ac-dark : rgb(130,21,55);
  --accent  : rgb(220,90,90);
  --ac-light: rgb(240,140,130);

}


html {
  height: 100%;
  width: 100%;

  overflow: hidden;
}

body {
  height: 100%;
  width: 100%;

  overflow: hidden;
}

#root {
  height: 100%;
  width: 100%;

  overflow: hidden;
}

.App {
  height: 100%;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 5fr 1fr 5fr 1fr 5fr 1fr;
  grid-template-rows: 5% 45% 45% 5%;

  background-color: var(--mc-dark);

}

p {
  margin-bottom: 0.4em;
}

#stumbleSection {
  grid-column-start: 2;
  grid-column-end: 7;
  grid-row-start: 2;
  grid-row-end: 3;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 5% 30% 10% 30% 15%;
}

#stumbleButton {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
}

#submissionPanel {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 4;
  grid-row-end: 6;
}

#aboutSection {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;

  overflow-y: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}

#adSection {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 4;

  overflow-y: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}

#historySection {
  grid-column-start: 6;
  grid-column-end: 7;
  grid-row-start: 3;
  grid-row-end: 4;

  overflow-y: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.button {
  cursor: pointer;
  border-radius: 25px;
  border: solid black;
  box-shadow: 10px 10px;

  font-size: 3.8em;
  font-weight: bold;

  background-color: var(--accent);
}

#submissionPanel {
  display: flex;
  flex-direction: column;
}

#submissionInput {
  border: solid black;
  border-radius: 25px;
  background-color: var(--ac-light);

  font-size: 3.5em;
  font-weight: bold;
}

#submissionInput::placeholder {
  color: var(--ac-dark);
  text-align: center;
}

#submissionInput:focus {
  outline: 3px solid var(--ac-dark);
}

#aboutLinks {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 18pt;
  justify-self: flex-end;
}

#historyList {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;

  gap: 5%;
}

.historyItem {
  height: 50px;
  width: 100%;

  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  grid-template-rows: 1fr;
}

.historySite {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;

  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

}

.historyLike {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;

  text-align: center;
}

.likeIcon {
  height: 2em;
  cursor: pointer;
}

.historyTalk {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;

  text-align: center;
}

.text {
  font-size: 18pt;
  color: var(--dark);
}

.title {
  font-size: 30pt;
  font-weight: bolder;

  text-align: center;
  margin-bottom: 0.5em;
}

.pulse {
  animation: pulse 500ms infinite;
}

#overlay {
  position:absolute;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px;

  background-color: rgba(0, 0, 0, 0.6);
}

#modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40vw;
  height: 50vh;
  margin-top: -25vh;
  margin-left: -20vw;

  border-radius: 10px;
  border: solid black;
  background-color: var(--ac-light);

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

#modalText {
  font-size: 30pt;
  align-self: flex-start;
  margin-left: 5%;
  margin-right: 5%;
}

#modalButton {
  width: 50%;
  background-color: var(--accent);
}

#adSectionContent {
    display: flex;
    flex-direction: column;

    cursor: pointer;
}

#adImage {
  align-self: center;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }

}

@media screen and (max-width: 1600px) {
  .App {
    grid-template-columns: 5vw 90vw 5vw;
    grid-template-rows: 40vh 40vh 25vh 50vh;
    row-gap: 2vh;

    overflow-y: scroll;
  }

  #stumbleSection {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  #aboutSection {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  #adSection {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
  }

  #historySection {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
  }
}


@media screen and (max-height: 750px) {
  #stumbleButton {
    font-size: 3em;
  }

  #submissionInput {
    font-size: 1em;
  }

  #submitButton {
    font-size: 3em;
  }

  #aboutSection {
    border: 1px solid black;
    margin-left: 15px;
    margin-right: 15px;
    padding: 10px;
  }

  #aboutSectionContent {
    padding: 10px;
    overflow: scroll;
  }

  #adSection {
    border: 1px solid black;
    margin-left: 15px;
    margin-right: 15px;
    padding: 10px;
  }

  #adSectionContent {
    padding: 10px;
    overflow: scroll;
  }

  .likeIcon {
    height: 1.5em;
  }
}
